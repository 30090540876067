import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { type JSXElementConstructor, useContext } from "react";

// ---------------------------------------------- Prerequisites ------------------------------------------------
// The component you are using this in needs to be wrapped in a PromiseDialogProvider in order for this to work
// ---------------------------------------------- Usage Example ------------------------------------------------
// import { usePromiseDialogContext } from "@/components/PromiseDialog";

// const YourFunctionComponent = () => {
//   const promiseDialog = usePromiseDialogContext();

//   const someFunc = async () => {
//     const result = await promiseDialog({
//       // dialog title
//       title: "Select an Option",
//       // dialog content
//       content: <div>select an action</div>,
//       // dialog actions
//       actions: [
//         {
//           // value returned by clicking on this option
//           value: 1,
//           // if you want the onClick param to be automatically added and
//           // set to resolve the promise, you can add the params to the
//           // component you specify here
//           component: (params) => <Button {...params}>Option 1</Button>,
//         },
//         {
//           value: 2,
//           component: (params) => <Button {...params}>Option 2</Button>,
//         },
//         {
//           value: 3,
//           component: (params) => <Button {...params}>Option 3</Button>,
//         },
//       ],
//       // specify whether the user is allowed to click outside of the dialog to close it or not
//       locked: false,
//     });

//     // result could be 1, 2, 3, or null.
//     // result would be null if the user clicked outside
//     // of the dialog without clicking an option
//     console.log(result)
//   };
// };

type ActionParams = {
  onClick: () => void;
  key: any;
};

type Action = {
  value: any;
  component: JSXElementConstructor<ActionParams>
};

type PromiseDialogProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  resolve: (val) => void;
  title?: string;
  content?: any;
  actions?: Action[];
  locked?: boolean;
};

const PromiseDialog = (props: PromiseDialogProps) => {
  const { open, setOpen, resolve, content, locked } = props;
  const title = props.title || "Are you sure?";

  const defaultActions: Action[] = [
    {
      value: false,
      component: (params: ActionParams) => (
        <Button {...params} color={"secondary"}>
          No
        </Button>
      ),
    },
    {
      value: true,
      component: (params: ActionParams) => <Button {...params}>Yes</Button>,
    },
  ];

  const actions = props.actions || defaultActions;

  const handleResolve = (val) => {
    setOpen(false);
    resolve(val);
  };

  return (
    <Dialog open={open} onClose={locked ? null : () => handleResolve(null)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {actions.map(({component: Component , value}, i) => {
          // I aliased component as Component, because
          // JSX elements should start with an uppercase letter.
          return (
            <Component
              onClick={() => handleResolve(value)}
              key={`action-button-${i}`}
            />
          )
        })}
      </DialogActions>
    </Dialog>
  );
};

const PromiseDialogContext = React.createContext<PromiseResult | undefined>(
  undefined
);

type Props = {
  title?: string;
  content?: any;
  actions?: Action[];
  locked?: boolean;
};

type PromiseResult = (props: Props) => Promise<any>;

export const PromiseDialogProvider = ({ children }) => {
  const [promiseDialogProps, setPromiseDialogProps] =
    React.useState<PromiseDialogProps>({
      open: false,
      resolve: (_) => {},
      setOpen: (val: boolean) =>
        setPromiseDialogProps((old) => {
          return { ...old, open: val };
        }),
    });

  const promise = (props: Props): Promise<any> => {
    return new Promise((resolve) => {
      setPromiseDialogProps((old) => {
        return {
          ...old,
          open: true,
          resolve: resolve,
          ...props,
        };
      });
    });
  };

  return (
    <PromiseDialogContext.Provider value={promise}>
      {children}
      <PromiseDialog {...promiseDialogProps} />
    </PromiseDialogContext.Provider>
  );
};

export const usePromiseDialogContext = () => useContext(PromiseDialogContext);
